import { News, NewsService } from '@common/clients/api';
import { ApiBaseRequest } from '@common/clients/request';
import { ContextData, defaultArrayCatch } from '@common/defaults';

type FetchParams = {
    isMedia?: boolean;
    functionality?: string;
};

export const fetchMostReadNews =
    (fetchParams: FetchParams) =>
    async (contextData: ContextData, isClientSide: boolean = true): Promise<News[]> => {
        const request = new ApiBaseRequest(contextData, isClientSide);
        const newsService = new NewsService(request);
        const response = await newsService
            .getMostReadNewsByDomainId({
                contextId: contextData.context.id,
                experimentGroup: contextData.experimentGroup,
                isMedia: fetchParams.isMedia,
                functionality: fetchParams.functionality,
            })
            .catch(defaultArrayCatch);
        return response || [];
    };
