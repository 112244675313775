import { FC, ReactNode } from 'react';

import styles from './Header.module.scss';

interface Props {
    children: ReactNode;
    classes?: string;
    flexDirection?: 'column' | 'row';
}

export const Header: FC<Props> = ({ children, classes, flexDirection }) => (
    <header className={`${classes} ${styles.Header} ${flexDirection ? styles[flexDirection] : ''}`}>
        {children}
    </header>
);
