import { FC, ReactNode } from 'react';

import styles from './Widget.module.scss';

interface Props {
    children: ReactNode;
    classes?: string;
}

export const Widget: FC<Props> = ({ children, classes }) => (
    <section className={`${styles.Widget} ${classes}`}>{children}</section>
);

export default Widget;
