import { createElement, FC, PropsWithChildren } from 'react';

import styles from './Flexbox.module.scss';

/**
 * Default component props
 */
interface Props extends PropsWithChildren {
    align?: 'start' | 'center';
    as: string;
    classes?: string;
    display?: 'flex' | 'inline-flex';
    justify?: 'start' | 'center';
    position?: 'absolute' | 'relative';
    direction?: 'row' | 'column';
}

/**
 * Flexbox component usable as a generic "container"
 * allowing for easy alignment and justification of children,
 * and reduce repeated flexbox styling.
 */

export const Flexbox: FC<Props> = ({
    align,
    as = 'div',
    classes = '',
    display = 'flex',
    justify,
    position = 'relative',
    children,
    direction = 'row',
}) => {
    const alignClass = styles[`align-${align}`];
    const displayClass = styles[display];
    const directionClass = styles[direction];
    const justifyClass = styles[`justify-${justify}`];
    const positionClass = styles[position];

    const flexClasses = [
        styles.Flexbox,
        alignClass,
        directionClass,
        displayClass,
        classes,
        justifyClass,
        positionClass,
    ].join(' ');

    return createElement(
        as,
        {
            className: flexClasses,
        },
        children,
    );
};
