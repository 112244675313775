interface PaginationInfo {
    nextPage: number;
    nextPerPage: number;
}

/**
 * Instead of loading 15 by 15, we first load 15, then 30, then 60, then 120
 * currentPage 1 (page=1, perPage = 15): 1 - 15
 * currentPage 2 (page=2, perPage = 15): 16 - 30
 * currentPage 3 (page=2, perPage = 30): 31 - 60
 * currentPage 4 (page=2, perPage = 60): 61 - 120
 * currentPage 5 (page=2, perPage = 120): 121 - 240
 * currentPage 6 (page=2, perPage = 240): 241 - 480
 * currentPage 7 (page=2, perPage = 480): 481 - 960
 * and since the pagination limit is usually 500 we always cap at the amount at 500
 * currentPage 8 (page=3, perPage = 480): 961 - 1440
 * currentPage 9 (page=4, perPage = 480): 1441 - 1920
 * @param page the current page
 * @param perPage how many items perPage (initially)
 * @returns the pagination info - the new page index and the new value items per page
 */
export const getIncrementalNextPage = (page: number, perPage: number): PaginationInfo => {
    let nextPage = page;
    let nextPerPage = perPage;

    if (perPage && nextPage > 2) {
        nextPage = 2;
        nextPerPage = perPage * Math.pow(2, page - 2);
    }

    // limit to 500 unless specifically requested above 500
    if (nextPerPage > 500 && perPage < 500) {
        // for the 15 perPage example in the top comment above this would become 7
        const maxPage = Math.floor(Math.sqrt(500 / perPage)) + 2;
        // for the 15 perPage example in the top comment and page 8 this would become 3
        nextPage = page - maxPage + 2;
        // for the 15 perPage example in the top comment this becomes 480
        nextPerPage = perPage * Math.pow(2, maxPage - 2);
    }

    return {
        nextPage,
        nextPerPage,
    };
};
