import { Logo } from '@common/atoms/Logo';

import styles from './ProTag.module.scss';

export const ProTag = ({
    isPremium,
    tag,
    isInverted,
}: {
    isPremium?: boolean;
    tag?: string;
    isInverted?: boolean;
}) => (
    <span className={` ${styles.ProTag} ${isPremium ? styles.premium : ''} `}>
        {isInverted ? <Logo.proInverse /> : <Logo.pro />}
        {tag ? <span className={styles['pro-text']}>{tag}</span> : null}
    </span>
);
