import { createElement, CSSProperties, FC, ReactElement } from 'react';

export type HeadingTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export interface Props {
    as: HeadingTag;
    heading?: string | ReactElement;
    className?: string;
    classes?: string;
    style?: CSSProperties;
}

export const Heading: FC<Props> = ({ as, heading, className, classes, style }: Props) =>
    heading ? createElement(as, { className: `${className} ${classes}`, style }, heading) : null;

Heading.displayName = 'Heading';
