import { FC, ReactNode } from 'react';

import styles from '../SimpleComment.module.scss';

export interface Props {
    metaIcon: ReactNode;
    metaSuffix: string;
    count: number;
}

export const CommentMeta: FC<Props> = ({ metaIcon, metaSuffix, count }) => (
    <div className={styles['meta']}>
        {metaIcon ? metaIcon : ''}
        <span>
            {count ? count.toString() : '0'} {metaSuffix ? metaSuffix : ''}
        </span>
    </div>
);
