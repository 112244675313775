import { GrandPrix, News, PlatformID } from '@common/clients/api';
import type { SimpleCommentProps, Variant } from '@web/molecules';
import type { GrandPrixWidgetProps } from '@web/molecules/GrandPrixWidget';
import type { NewsBlockItemProps } from '@web/molecules/NewsBlockItem';
import { SidebarSequence } from '@web/routing/types/SidebarSequenceByRoute';

export type SidebarSequenceWidgets = Record<SidebarSequence, Array<WidgetType>>;
export type PlatformIncludeWidgets = Record<PlatformID, Array<WidgetType>>;

/**
 * Type representing the available widget types.
 */

export enum WidgetType {
    LATEST_PODCASTS = 'latest-podcasts',
    MOST_COMMENTED = 'most-commented',
    MOST_LIKED_COMMENTS = 'most-liked-comments',
    MOST_READ = 'most-read',
    MOST_VIEWED = 'most-viewed',
    PRO_NEWS = 'pro-news',
    RECENT_NEWS = 'recent-news',
    TRANSFER_RUMOUR = 'transfer-rumour',
    UPCOMING_GP = 'upcoming-gp',
}

/**
 * Type representing the raw data fetched from the server for each widget.
 */
export type SidebarServerData = {
    [WidgetType.LATEST_PODCASTS]?: News[];
    [WidgetType.MOST_COMMENTED]?: News[];
    [WidgetType.MOST_LIKED_COMMENTS]?: News[];
    [WidgetType.MOST_READ]?: News[];
    [WidgetType.MOST_VIEWED]?: News[];
    [WidgetType.PRO_NEWS]?: News[];
    [WidgetType.RECENT_NEWS]?: News[];
    [WidgetType.TRANSFER_RUMOUR]?: News[];
    [WidgetType.UPCOMING_GP]?: GrandPrix;
};

export type WidgetData = {
    [key in keyof SidebarServerData]: SidebarServerData[key];
};

/**
 * Type representing the transformed props passed to each widget.
 */
export type SidebarWidgetProps = {
    [WidgetType.LATEST_PODCASTS]?: NewsBlockItemProps[];
    [WidgetType.MOST_COMMENTED]?: NewsBlockItemProps[];
    [WidgetType.MOST_LIKED_COMMENTS]?: SimpleCommentProps[];
    [WidgetType.MOST_READ]?: NewsBlockItemProps[];
    [WidgetType.MOST_VIEWED]?: NewsBlockItemProps[];
    [WidgetType.PRO_NEWS]?: NewsBlockItemProps[];
    [WidgetType.RECENT_NEWS]?: NewsBlockItemProps<Variant.TIMELINE>[];
    [WidgetType.TRANSFER_RUMOUR]?: NewsBlockItemProps[];
    [WidgetType.UPCOMING_GP]?: GrandPrixWidgetProps;
};

export type WidgetProps = {
    [key in keyof SidebarWidgetProps]: SidebarWidgetProps[key];
};

export interface SidebarData extends SidebarWidgetProps {
    sequence: SidebarSequence;
}
