import { FC, ReactNode } from 'react';

import { CommentUser } from '@common/clients/api';
import { Avatar } from '@web/atoms/Avatar';

import { CommentCard } from './atoms/CommentCard';
import { CommentMeta } from './atoms/CommentMeta';

import styles from './SimpleComment.module.scss';

const COMMENT_ANCHOR_PREFIX = 'comment_';

export interface Props {
    id?: number;
    user?: CommentUser;
    likeCount?: number;
    metaSuffix?: string;
    metaIcon?: ReactNode;
    reactionTxt?: string;
    link?: string;
}

export const SimpleComment: FC<Props> = ({ id, user, likeCount, metaSuffix, metaIcon, reactionTxt, link }) =>
    user && reactionTxt ? (
        <div className={styles.SimpleComment}>
            <a href={`${link}#${COMMENT_ANCHOR_PREFIX}${id}`}>
                <Avatar className={styles.avatar} src={user.image} isRounded />
                <CommentCard
                    userName={user?.userName}
                    userLikeCount={user?.likeCount}
                    reactionTxt={reactionTxt}
                    role={user?.role}
                />
                {metaSuffix ? (
                    <CommentMeta metaIcon={metaIcon} metaSuffix={metaSuffix} count={likeCount || 0} />
                ) : null}
            </a>
        </div>
    ) : null;
