import { News, NewsService } from '@common/clients/api';
import { ApiBaseRequest } from '@common/clients/request';
import { ContextData, defaultArrayCatch } from '@common/defaults';

export const fetchMostCommentedNews = async (contextData: ContextData): Promise<News[]> => {
    const request = new ApiBaseRequest(contextData, true);
    const newsService = new NewsService(request);
    const response = await newsService
        .getMostCommentedNewsByDomainId({
            contextId: contextData.context.id,
            experimentGroup: contextData.experimentGroup,
        })
        .catch(defaultArrayCatch);
    return response;
};
