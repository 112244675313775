import { Comment, CommentService } from '@common/clients/api';
import { ApiBaseRequest } from '@common/clients/request';
import { ContextData, defaultArrayCatch } from '@common/defaults';

export const fetchMostLikedComments = async (
    contextData: ContextData,
    isClientSide: boolean = true,
): Promise<Comment[]> => {
    const request = new ApiBaseRequest(contextData, isClientSide);
    const commentService = new CommentService(request);
    const response = await commentService
        .getMostLikesCommentByDomainId({
            contextId: contextData.context.id,
        })
        .catch(defaultArrayCatch);
    return response;
};
