import { NewsCategory, NewsCategoryService } from '@common/clients/api';
import { ApiBaseRequest } from '@common/clients/request';
import { ContextData, defaultCatch } from '@common/defaults';

export const fetchNewsCategoryByFunctionality = async (
    contextData: ContextData,
    isClientSide: boolean,
    functionality: string,
): Promise<NewsCategory | undefined> => {
    const request = new ApiBaseRequest(contextData, isClientSide);
    const newsCategoryService = new NewsCategoryService(request);
    const response = await newsCategoryService
        .getByFunctionalityNewsCategoryByDomainId({
            contextId: contextData.context.id,
            functionality: functionality,
        })
        .catch(defaultCatch);
    return response;
};
