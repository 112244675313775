import { FC } from 'react';

import { Icon } from '@common/atoms/Icon';
import { News, PlatformID } from '@common/clients/api';
import { fetchMostReadNews } from '@web/handlers/fetchMostReadNews';
import { NewsBlockItem, NewsBlockItemProps } from '@web/molecules/NewsBlockItem';
import { WidgetType } from '@web/organisms/Sidebar/types';
import { SimpleWidget } from '@web/organisms/SimpleWidget';
import { SimpleWidgetConfig } from '@web/organisms/SimpleWidget/types';

import { FetcherProps } from './types';
import { mapFetchedData } from './utils';

interface Props {
    dataList?: NewsBlockItemProps[];
    platformID?: PlatformID;
}

export const widgetConfig: SimpleWidgetConfig = {
    title: 'most-viewed.title',
    metaKey: WidgetType.MOST_VIEWED,
    metaIcon: <Icon.show />,
    metaSuffix: 'most-watched.count',
};

export const Widget: FC<Props> = ({ dataList, platformID }) => {
    return (
        <SimpleWidget
            {...{
                ...widgetConfig,
                title: `${platformID}.${widgetConfig.title}`,
            }}
            ComponentItem={NewsBlockItem}
            dataList={dataList}
        />
    );
};

export const fetcherProps: FetcherProps<News[], NewsBlockItemProps[]> = {
    fetcher: fetchMostReadNews({ isMedia: true }),
    mapper: mapFetchedData('visitCount', true),
    storageKey: WidgetType.MOST_VIEWED,
};
