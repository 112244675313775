import { FC } from 'react';
import { SimpleWidget } from 'web/organisms/SimpleWidget';

import { Icon } from '@common/atoms/Icon';
import { Comment as FetchedComment } from '@common/clients/api';
import { fetchMostLikedComments } from '@web/handlers/fetchMostLikedComments';
import { SimpleComment, SimpleCommentProps } from '@web/molecules/SimpleComment';
import { WidgetType } from '@web/organisms/Sidebar/types';
import { SimpleWidgetConfig } from '@web/organisms/SimpleWidget/types';

import { FetcherProps } from './types';

interface Props {
    dataList?: SimpleCommentProps[];
}

export const widgetConfig: SimpleWidgetConfig = {
    title: 'popular-comments.title',
    metaKey: WidgetType.MOST_LIKED_COMMENTS,
    metaIcon: <Icon.like />,
    metaSuffix: 'popular-comments.count',
};

export const Widget: FC<Props> = ({ dataList }) => {
    return (
        <SimpleWidget<SimpleCommentProps>
            {...widgetConfig}
            ComponentItem={SimpleComment}
            dataList={dataList}
        />
    );
};

export const mapFetchedComments = (comments: FetchedComment[]) =>
    comments.map((comment) => ({
        ...comment,
        id: comment.id || 0,
        user: comment.user || {},
        likeCount: comment.likeCount || 0,
        reactionTxt: comment.reactionTxt || '',
    }));

export const fetcherProps: FetcherProps<FetchedComment[], SimpleCommentProps[]> = {
    fetcher: fetchMostLikedComments,
    mapper: mapFetchedComments,
    storageKey: WidgetType.MOST_LIKED_COMMENTS,
};
