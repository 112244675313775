import { ReactElement } from 'react';

import { TabProps } from '../Tabs/Tabs';

import styles from '../Tabs/Tabs.module.scss';

export const Tab = ({
    label,
    dataKey,
    highlighted,
    onTabClick,
}: {
    label: string | ReactElement;
    dataKey: string;
    highlighted: boolean;
    onTabClick: TabProps['onTabClick'];
}) => (
    <li
        onClick={() => onTabClick(dataKey)}
        className={`${styles.tab} ${highlighted ? styles['tab-highlighted'] : ''}`}
    >
        {label}
    </li>
);
