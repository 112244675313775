import { News } from '@common/clients/api';
import { ContextData } from '@common/defaults';
import { ItemsPerComponent } from '@common/types';
import { NewsFunctionality } from '@common/types/NewsFunctionality';
import { fetchNews } from '@web/handlers/fetchNews';

import { fetchNewsCategoryByFunctionality } from '../fetchNewsCategoryByFunctionality';

type FetchParams = {
    perWidget: ItemsPerComponent;
    functionality: NewsFunctionality;
};
export const fetchCategoryNews =
    (fetchParams: FetchParams) =>
    async (contextData: ContextData, isClientSide: boolean = false): Promise<News[]> => {
        const newsCategory = await fetchNewsCategoryByFunctionality(
            contextData,
            isClientSide,
            fetchParams.functionality,
        );
        const newsCatID = newsCategory?.id;
        if (newsCatID) {
            const response = await fetchNews({
                contextData,
                isClientSide,
                newsCatID,
                perPage: fetchParams.perWidget,
            });
            return response.data;
        }
        return [];
    };
